import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const HealingOptionsLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Brinton"
    institution="Healing Options"
    referralCode="HEALINGOPTIONS"
    physicianURL="http://healing-options.com/"
  />
)

export default HealingOptionsLandingPage
